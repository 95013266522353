<template>
    <div class="body">
        <div class="coupon-wrapper">
            <div class="coupon-top-wrapper">
                <div class="coupon-top-left-wrapper">
                    <p>{{info.title}}</p>
                    <p>限{{info.valid_end_time}}前使用</p>
                </div>
                <div class="coupon-top-right-wrapper">
                    <p><span>￥</span>{{computedMoney}}</p>
                    <p v-if="info.money != 0">满{{computedFullMoney}}元使用</p>
                    <p v-else>无门槛使用</p>
                </div>
            </div>
            <div class="coupon-bottom-wrapper">
                <div class="coupon-title">
                    <p></p>
                    <p>使用范围</p>
                </div>
                <div class="coupon-text-wrapper">
                    <div v-if="info.type == 2">{{validRange}}</div>
                    <div v-else-if="info.type == 3">
                        <div v-for="(items, index) in validRange" :key="index">
                            <p>{{items}}；</p>
                        </div>
                    </div>
                    <div v-else-if="info.type == 1">
                        <div v-for="(items, index) in validRange" :key="index">
                            <p>{{items.name}}；</p>
                        </div>
                    </div>
                </div>
                <div class="coupon-title">
                    <p></p>
                    <p>使用须知</p>
                </div>
                <div class="coupon-text-wrapper">
                    <div class="coupon-des-wrapper">
                        <div v-for="items in info.describe" :key="items">
                            <div class="coupon-des">{{items}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'
  import { moneyFen2Yuan } from '../../utils/tools'
  import FloatNav from '../../components/common/FloatNav'

  export default {
    name: 'Coupon',
    components: { FloatNav },
    mixins: [globalConfig],
    data () {
      return {
        couponId: '',
        info: [],
        validRange: []
      }
    },
    methods: {
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/Coupon/getCouponInfo', {
          couponId: this.couponId
        }).then((response) => {
          const data = response.data
          console.log(data)
          that.info = data.info
          that.validRange = data.valid
        })
      }
    },
    computed: {
      computedMoney: function () {
        return moneyFen2Yuan(this.info.money)
      },
      computedFullMoney: function () {
        return moneyFen2Yuan(this.info.full_money)
      }
    },
    created () {
      this.couponId = this.$route.params.id
      this.init()
    }
  }
</script>

<style scoped>
    *{
        box-sizing: border-box;
    }
    .body{
        background: #eeeeee;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .coupon-wrapper{
        margin-top: 1rem;
    }
    .coupon-top-wrapper{
        width: 92vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/0528bg1.png") no-repeat;
        background-size: 100% 100%;
        margin: 0 4vw;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3vw 4vw;
    }
    .coupon-bottom-wrapper{
        width: 92vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/0528bg2.png") no-repeat ;
        background-size: 100% 100%;
        margin: 0 4vw;
        padding: 5vw ;
    }
    .coupon-bottom-wrapper > * {
        margin-top: 0.5rem;
    }
    .coupon-top-left-wrapper{
        width: 50vw;
    }
    .coupon-top-left-wrapper p:nth-of-type(1){
        font-size: 5vw;
        font-weight: 800;
        margin:0 0 2vw 0;
    }
    .coupon-top-left-wrapper p:nth-of-type(2){
        font-size: 3.2vw;
    }
    .coupon-top-right-wrapper{
        width: 30vw;
    }
    .coupon-top-right-wrapper p:nth-of-type(1){
        margin-top: -2vw;
        font-size: 8vw;
        text-shadow: 1px 1px 2px #ccc;
    }
    .coupon-top-right-wrapper span{
        font-size: 3.2vw;
    }
    .coupon-top-right-wrapper p:nth-of-type(2){
        font-size: 3.2vw;
    }
    .coupon-title{
        display: flex;
        align-items: center;
    }
    .coupon-title p:nth-of-type(1){
        width: 1vw;
        height: 4vw;
        background-color: #ff3b30;
        margin: 0 2vw;
    }
    .coupon-title p:nth-of-type(2){
        color: #888;
        font-size: 3.8vw;
    }
    .coupon-text-wrapper{
        display: flex;
        font-size: 3.6vw;
        color: #888;
        font-weight: 500;
        margin-left: 2vw;
    }
    .coupon-des-wrapper {
        /*width: 56vw;*/
        display: flex;
        flex-wrap: wrap
    }
    .coupon-des{
        /*width: 56vw;*/
        /*margin-top: 0.1rem;*/
    }
</style>
